<template>
  <thead class="text-xs text-label font-medium text-left" :class="headClasses">
    <slot />
    <tr ref="tableHeaderRow">
      <th
        v-if="selectAll || selectOne"
        class="px-4 pl-5 h-13 break-words font-medium"
      >
        <div class="flex items-center space-x-1-5">
          <lf-checkbox
            v-if="showHeadSelectAll && !selectOne"
            :value="isSelectedAllRows"
            :model-value="isSelectedAllRows"
            name="select_all"
            :disabled="disabledCheckboxes"
            no-margin
            @update:model-value="handleToggleAllCheckboxes"
          >
            <span v-if="selectTitle" class="ml-4 text-gray-400">
              {{ selectTitle }}
            </span>
          </lf-checkbox>
          <div
            v-if="
              hasSelectModeOption &&
              !isSelectModeEnabled &&
              canDisableSelectMode
            "
          >
            <button
              v-tooltip="$t('COMMON.MULTISELECT_OFF')"
              :data-cy="`multiselectButton-${isSelectModeEnabled}`"
              class="h-8 w-8 rounded hover:bg-grey-hover mt-[2px]"
            >
              <icon-base
                :icon="IconXCircle"
                @click="emit('multiselect:change', !isSelectModeEnabled)"
              />
            </button>
          </div>
        </div>
      </th>
      <template
        v-for="(column, index) in columns"
        :key="`column-head-${column.key}`"
      >
        <th
          class="px-5 pl-5 h-13 font-medium relative lf-table-header"
          :class="[{ 'whitespace-nowrap': headerNoWrap }, column.headerClass]"
          :data-cy="`table-header-column-${column.key}`"
        >
          <div class="th-wrapper">
            <loader :is-loading="column.isSubColumnLoading ?? false" />
            <div class="flex items-center space-x-2 w-full">
              <div
                v-if="hasSelectModeOption && index === 0 && isSelectModeEnabled"
              >
                <button
                  v-tooltip="$t('COMMON.MULTISELECT')"
                  :data-cy="`multiselectButton-${isSelectModeEnabled}`"
                  class="h-8 w-8 rounded hover:bg-grey-hover"
                >
                  <icon-base
                    :icon="IconCheckSquare"
                    @click="emit('multiselect:change', !isSelectModeEnabled)"
                  />
                </button>
              </div>
              <div class="flex justify-between space-x-4 w-full">
                <div
                  class="flex items-start relative cursor-pointer"
                  :class="flexJustifyContentClass(column.headerClass)"
                >
                  <span v-if="!column.filterComponent?.props?.filterIcon">
                    {{ column.label }}
                  </span>
                  <table-filters
                    v-else
                    :column="column"
                    :toggle-filter="toggleFilter === index + 1"
                    :scrollable-element-id="scrollableElementId"
                    :initial-component-render="
                      Boolean(column.filterComponent?.initialRender)
                    "
                    :data-cy="`table-header-filter-${column.key}`"
                    @click.prevent.stop="toggleFilter = index + 1"
                    @close-filter="closeFilter"
                  />
                </div>
                <button
                  v-if="pinOption && index === 0"
                  v-tooltip="
                    pinEnabled
                      ? $t('DEALS.TABLE_HEADERS.UNPIN_COLUMN')
                      : $t('DEALS.TABLE_HEADERS.PIN_COLUMN')
                  "
                  data-cy="pinButton"
                  @click="$emit('click:pin', !pinEnabled)"
                >
                  <icon-base
                    height="12"
                    width="12"
                    :icon="IconPinSecondary"
                    :class="{
                      'text-blue-600': pinEnabled
                    }"
                  />
                </button>
              </div>
            </div>
            <button
              v-if="column?.subColumns"
              class="absolute -top-0.5 -right-7 border border-gray-200 rounded-md bg-white th-arrow-button"
              :data-cy="'sub-columns-button-' + column.key"
              @click="toggleSubColumns(column.key)"
            >
              <icon-base
                icon="arrow-right"
                class="mt-0.5"
                :class="{ 'transform rotate-180': !!column.subColumns?.length }"
              />
            </button>
          </div>
        </th>
        <template v-if="column?.subColumns?.length">
          <th
            v-for="(subColumn, index) in column.subColumns"
            :key="`subColumn-head-${subColumn.key}`"
            class="px-4 pl-5 h-13 font-medium bg-gray-50"
            :class="{
              'whitespace-nowrap': headerNoWrap
            }"
          >
            <div class="flex items-start relative cursor-pointer">
              <span v-if="!subColumn.filterComponent?.props?.filterIcon">
                {{ subColumn.label }}
              </span>
              <table-filters
                v-else
                :column="subColumn"
                :toggle-filter="toggleFilter === index + columns.length + 1"
                :scrollable-element-id="scrollableElementId"
                @click.prevent.stop="toggleFilter = index + columns.length + 1"
                @close-filter="closeFilter"
              />
            </div>
          </th>
        </template>
      </template>
    </tr>
  </thead>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import LfCheckbox from "../inputs/LfCheckbox.vue";
import TableFilters from "./TableFilters.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconPinSecondary from "@/components/icons/IconPinSecondary.vue";
import IconCheckSquare from "@/components/icons/IconCheckSquare.vue";
import IconXCircle from "@/components/icons/IconXCircle.vue";
import type { PropType } from "vue";
import type { IDealsColumn } from "@/models/applications";

const props = defineProps({
  columns: {
    type: Array as PropType<Array<IDealsColumn>>,
    default: null
  },
  headerNoWrap: {
    type: Boolean,
    default: true
  },
  selectAll: {
    type: Boolean,
    default: false
  },
  selectOne: {
    type: Boolean,
    default: false
  },
  selectTitle: {
    type: String,
    default: ""
  },
  showHeadSelectAll: {
    type: Boolean,
    default: true
  },
  headClasses: {
    type: String,
    default: ""
  },
  setHeaderMinWidths: {
    type: Boolean,
    default: false
  },
  // this is only used if setHeaderMinWidths is true
  isLoading: {
    type: Boolean,
    default: false
  },
  scrollableElementId: {
    type: String,
    default: ""
  },
  isSelectedAllRows: {
    type: Boolean,
    default: false
  },
  pinEnabled: {
    type: Boolean,
    required: true
  },
  pinOption: {
    type: Boolean,
    default: false
  },
  disabledCheckboxes: {
    type: Boolean,
    default: false
  },
  hasSelectModeOption: {
    type: Boolean,
    default: false
  },
  isSelectModeEnabled: {
    type: Boolean,
    default: false
  },
  canDisableSelectMode: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits<{
  (eventName: "toggleAllCheckboxes", value: boolean): void;
  (eventName: "toggleSubColumn", payload: string): void;
  (eventName: "click:pin", value: boolean): void;
  (eventName: "multiselect:change", value: boolean): void;
}>();
const tableHeaderRow = ref<HTMLElement | null>(null);
const toggleFilter = ref(0);

const toggleSubColumns = (key: string) => {
  emit("toggleSubColumn", key);
};

function toggleHeaderStyles(remove = false): void {
  tableHeaderRow.value
    ?.querySelectorAll<HTMLElement>(".lf-table-header")
    .forEach((header: HTMLElement) => {
      header.style.minWidth = remove ? "" : `${header.offsetWidth}px`;
    });
}

const handleToggleAllCheckboxes = (value: boolean) => {
  emit("toggleAllCheckboxes", value);
};

const closeFilter = () => (toggleFilter.value = 0);

const flexJustifyContentClass = (headerClass: string | undefined) => {
  return headerClass === "text-right" ? "justify-end w-full" : "";
};

watch(
  () => props.isLoading,
  (isLoading) => {
    if (props.setHeaderMinWidths) {
      isLoading ? toggleHeaderStyles() : toggleHeaderStyles(true);
    }
  }
);
</script>

<style scoped>
.lf-table-header:last-child .th-wrapper {
  @apply flex items-center gap-2-5;
}

.lf-table-header:last-child .th-arrow-button {
  @apply static;
}
</style>
